import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import BillForm from './pages/BillForm'
import Login from './pages/Login'
import Categories from './pages/Categories'
import Register from './pages/Register'
import NewCat from './pages/NewCat'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={ <BillForm/> } /> */}
        <Route path="/" element={ <Login /> } />
        <Route path='/categories' element={ <Categories /> } />
        <Route path="/cat" element={ <NewCat/> } />
        <Route path='/register' element={ <Register /> } />
      </Routes>
    </BrowserRouter>
  )
}

export default App