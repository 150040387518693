import { AppBar, Box, Button, Container, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, Switch, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react'
import logo from "../images/Withe.png"
import electricity from "../images/electricity.jpg"
import StarsIcon from '@mui/icons-material/Stars';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import EastIcon from '@mui/icons-material/East';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import BoltIcon from '@mui/icons-material/Bolt';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import CastIcon from '@mui/icons-material/Cast';
import ShopIcon from '@mui/icons-material/Shop';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';


const NewCat = () => {

  const [elect, setElect] = React.useState(false);
  const [state , setState] = React.useState('');
  const [board , setBoard] = React.useState('');
  const [account , setAccount] = React.useState('');
  const [checked, setChecked] = React.useState(false);


  const handleElectClose = () => {
    setElect(false);
  };

  const handleChange = (event) => {
    setState(event.target.value);
    console.log("satte : ",event.target.value)
  };

  const handleBoard = (event) => {
    setBoard(event.target.value);
    console.log("board : ",event.target.value)
  }

  function billPay(){
    window.location.reload();
  window.alert("bill paid successfully");
}

const handleSwitchChange = (event) => {
  setChecked(event.target.checked);
  console.log(event.target.checked)
};


  return (
  
    <Box  sx={{ minHeight:"180vh", backgroundColor: checked ? "#424242" : null }} >
        <Box>
            <AppBar position="static" sx={{ backgroundColor:"#212121" }} >
                <Toolbar sx={{ display:'flex', alignItems:'center', justifyContent:"space-between", height:"5rem" }} >
                    <Box component='img' src={logo} sx={{ width:"5rem" }} />
                    <Typography sx={{ fontFamily:'montserrat', fontSize:20 }} >RanaPay Categories</Typography>
                </Toolbar>
            </AppBar>
            <Switch sx={{ float:'right' }}
               checked={checked}
               onChange={handleSwitchChange}
               inputProps={{ 'aria-label': 'controlled' }}
            />

            </Box>


            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', marginTop:"4rem"}} >              

              <Box sx={{ width:"60%" }} >
                {
                  checked ?  <Typography sx={{ fontFamily:'montserrat', fontSize:50, fontWeight:800, color:"white", textTransform:'uppercase' }} >a <span style={{color:"#1e88e5"}} >safe</span> and <span style={{color:"#1e88e5"}} >easy</span>  way to pay your bills. </Typography> : 
                  <Typography sx={{ fontFamily:'montserrat', fontSize:50, fontWeight:800, color:"#656869", textTransform:'uppercase' }} >a <span style={{color:"#1e88e5"}} >safe</span> and <span style={{color:"#1e88e5"}} >easy</span>  way to pay your bills. </Typography>

                }

                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:'1.2rem', marginTop:"5rem", color:"#9ca19c" }} > <StarsIcon sx={{ color:"gray", fontSize:"1rem" }} /> RanaPay protects your money with the best-in-class security systems that helps minimise financial frauds. </Typography>
              </Box>

              <Box sx={{ }} >
                <Box component='img' sx={{ width:350 }} src={electricity}  />
              </Box>

            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', marginTop:'5rem' }} >  
              <Typography sx={{ fontFamily:"montserrat", fontWeight:600, fontSize:"2rem", color: checked ? "white" : "#424242", textTransform:"capitalize" }} >explore RanaPay </Typography>
            </Box>

            <Box sx={{ display:'flex', justifyContent:"center" }} >


            <Box sx={{ marginTop:"1rem" , display:'grid', gridTemplateColumns:"repeat(3,1fr)" ,alignItems:"center", justifyContent:"center", gap:'5rem', marginTop:'3rem' }} >

              <Paper onClick={() => setElect(true)} className='card' elevation={24} sx={{ cursor:'pointer', width:"22rem", height:"12rem" , border:"1px solid grey" , backgroundColor:"#0091ea", borderRadius:4}} >

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center" }} >

                <Box sx={{ width:"60%" }} >
                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize', fontSize:"1.6rem", color:'white', marginTop:'0.9rem' }} >electricity bill</Typography>

                  <Typography sx={{ fontFamily:'montserrat', fontSize:"0.9rem" ,fontWeight:500, color:"#d1cfcf", textTransform:"lowercase", marginTop:'1rem' }} > Pay your electricity bill and keep the brightness shining.</Typography>

                    <Box>

                  <DoubleArrowIcon sx={{ marginTop:"1.5rem", color:'white'}}  />

                    </Box>
                </Box>

                <Box className="container" sx={{ width:"30%", display:'flex', alignItems:'center', justifyContent:'center' }} >
                <Box className="circle" />
                  <BoltIcon className='text' sx={{ fontSize:'4rem', color:"#e0e0e0" }} />     
                </Box>
                </Box>  
              </Paper>








              <Paper onClick={() => window.alert("service comming soon")} className='card' elevation={24} sx={{ cursor:'pointer', width:"22rem", height:"12rem" , border:"1px solid grey" , backgroundColor:"#2196f3", borderRadius:4}} >

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center" }} >

                <Box sx={{ width:"60%" }} >
                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize', fontSize:"1.5rem", color:'white', marginTop:'0.9rem' }} >Mobile Recharge</Typography>

                  <Typography sx={{ fontFamily:'montserrat', fontSize:"0.9rem" ,fontWeight:500, color:"#d9d9d9", textTransform:"lowercase", marginTop:'1rem' }} >Ignite your mobile world with a recharge that fuels your connections</Typography>

                  <DoubleArrowIcon sx={{ marginTop:"1.5rem", color:'white'}}  />
                </Box>

                <Box className="container" sx={{ width:"30%", display:'flex', alignItems:'center', justifyContent:'center' }} >

                <Box className="circle" />
                  <MobileFriendlyIcon className='text' sx={{ fontSize:'4rem', color:"#e0e0e0" }} />     
                </Box>

                </Box> 
              </Paper>


                
                




              <Paper onClick={() => window.alert("service comming soon")} className='card' elevation={24} sx={{ cursor:'pointer', width:"22rem", height:"12rem" , border:"1px solid grey" , backgroundColor:"#0277bd", borderRadius:4}} >

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center" }} >

              <Box  sx={{ width:"60%" }} >
                
                <Typography  sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize', fontSize:"1.6rem", color:'white', marginTop:'0.9rem' }} >Water bill</Typography>

                <Typography sx={{ fontFamily:'montserrat', fontSize:"0.9rem" ,fontWeight:500, color:"#f5f2f2", textTransform:"lowercase", marginTop:'1rem' }} >Paying your water bill is a commitment to our shared aquatic legacy.</Typography>

                <DoubleArrowIcon sx={{ marginTop:"1.5rem", color:'white'}}  />
              </Box>

              <Box className="container" sx={{ width:"30%", display:'flex', alignItems:'center', justifyContent:'center' }} >
              <Box className="circle" />
                <WaterDropIcon className="text" sx={{ fontSize:'4rem', color:"#e0e0e0" }} />     
              </Box>


              </Box>

      </Paper>








      <Paper onClick={() => window.alert("service comming soon")} className='card' elevation={24} sx={{ cursor:'pointer', width:"22rem", height:"12rem" , border:"1px solid grey" , backgroundColor:"#26c6da", borderRadius:4}} >

        <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center" }} >

        <Box sx={{ width:"60%" }} >
          <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize', fontSize:"1.1rem", color:'white', marginTop:'0.9rem' }} >DTH And TV Recharge</Typography>

          <Typography sx={{ fontFamily:'montserrat', fontSize:"0.9rem" ,fontWeight:500, color:"#e8e8e8", textTransform:"lowercase", marginTop:'1rem' }} >make your moments blockbuster with lighting speed tv recharge</Typography>

          <DoubleArrowIcon sx={{ marginTop:"1.5rem", color:'white'}}  />
        </Box>

        <Box className="container" sx={{ width:"30%", display:'flex', alignItems:'center', justifyContent:'center' }} >

        <Box className="circle" />
          <CastIcon className='text' sx={{ fontSize:'4rem', color:"#e0e0e0" }} />     
        </Box>

        </Box> 
        </Paper>









        <Paper onClick={() => window.alert("service comming soon")} className='card' elevation={28} sx={{ cursor:'pointer', width:"22rem", height:"12rem" , border:"1px solid grey" , backgroundColor:"#304ffe", borderRadius:4}} >

        <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center" }} >

        <Box sx={{ width:"60%" }} >
          <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize', fontSize:"1.1rem", color:'white', marginTop:'0.9rem' }} >GooglePlay Recharge</Typography>

          <Typography sx={{ fontFamily:'montserrat', fontSize:"0.9rem" ,fontWeight:500, color:"#e8e8e8", textTransform:"lowercase", marginTop:'1rem' }} >make your moments blockbuster with lighting speed tv recharge</Typography>

          <DoubleArrowIcon sx={{ marginTop:"1.5rem", color:'white'}}  />
        </Box>

        <Box className="container" sx={{ width:"30%", display:'flex', alignItems:'center', justifyContent:'center' }} >

        <Box className="circle" />
          <ShopIcon className='text' sx={{ fontSize:'4rem', color:"white" }} />     
        </Box>

        </Box> 
        </Paper>







        <Paper onClick={() => window.alert("service comming soon")} className='card' elevation={24} sx={{ cursor:'pointer', width:"22rem", height:"12rem" , border:"1px solid grey" , backgroundColor:"#2979ff", borderRadius:4}} >

          <Box sx={{ display:'flex', alignItems:'center', justifyContent:"center" }} >

          <Box sx={{ width:"60%" }} >
            <Typography sx={{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize', fontSize:"1.5rem", color:'white', marginTop:'0.9rem' }} >book cylinder</Typography>

            <Typography sx={{ fontFamily:'montserrat', fontSize:"0.9rem" ,fontWeight:500, color:"#e8e8e8", textTransform:"lowercase", marginTop:'1rem' }} >make your moments blockbuster with lighting speed tv recharge</Typography>

            <DoubleArrowIcon sx={{ marginTop:"1.5rem", color:'white'}}  />
          </Box>

          <Box className="container" sx={{ width:"30%", display:'flex', alignItems:'center', justifyContent:'center' }} >

          <Box className="circle" />
            <PropaneTankIcon className='text' sx={{ fontSize:'4rem', color:"white" }} />     
          </Box>

          </Box> 
          </Paper>

            </Box>

            </Box>










      <Dialog
        open={elect}
        onClose={handleElectClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth:{lg:"500px", xs:450},  // Set your width here
              },
            },
          }}      >
        <DialogTitle sx={{ fontFamily:"montserrat", textAlign:'center', width:{lg:450, xs:250}}} id="alert-dialog-title">
          {"Pay Electricity Bill"}
        </DialogTitle>
        <DialogContent sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:"column", gap:"1rem" }} >



        <FormControl variant='standard' sx={{ width:{lg:450, xs:250} }}>
        <InputLabel id="demo-simple-select-label"> State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="select state"
          value={state}
          onChange={handleChange}
        >
          <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
         
        </Select>
      </FormControl>


      {
            state ?   <FormControl variant='standard' sx={{ width:{lg:450, xs:250} }}>
            <InputLabel id="demo-simple-select-label"> Board</InputLabel>
    
        
            {
                state === "Uttar Pradesh" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Dakshinanchal Vidyut Vitran Nigam Limited (DVVNL)'>Dakshinanchal Vidyut Vitran Nigam Limited (DVVNL)</MenuItem>
              <MenuItem value="Madhyanchal Vidyut Vitran Nigam Limited (MVVNL)">Madhyanchal Vidyut Vitran Nigam Limited (MVVNL)</MenuItem>
              <MenuItem value="Paschimanchal Vidyut Vitran Nigam Limited (PVVNL)">Paschimanchal Vidyut Vitran Nigam Limited (PVVNL)</MenuItem>
              <MenuItem value="Purvanchal Vidyut Vitaran Nigam Limited (PUVVNL)">Purvanchal Vidyut Vitaran Nigam Limited (PUVVNL)</MenuItem>
              <MenuItem value="Uttar Pradesh Power Corporation Ltd. (UPPCL)">Uttar Pradesh Power Corporation Ltd. (UPPCL)</MenuItem>

    
            </Select> : null

            }

{
                state === "Punjab" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Punjab State Power Corporation Ltd. (PSPCL)'>Punjab State Power Corporation Ltd. (PSPCL)</MenuItem>
    
            </Select> : null

            }

{
                state === "Rajasthan" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Ajmer Vidyut Vitran Nigam Ltd. (AVVNL)'>Ajmer Vidyut Vitran Nigam Ltd. (AVVNL)</MenuItem>
              <MenuItem value="Bharatpur Electricity Services Ltd. (BESL)">Bharatpur Electricity Services Ltd. (BESL)</MenuItem>
              <MenuItem value="Bikaner Electricity Supply Limited">Bikaner Electricity Supply Limited</MenuItem>
              <MenuItem value="Jaipur Vidyut Vitran Nigam Ltd. (JVVNL)">Jaipur Vidyut Vitran Nigam Ltd. (JVVNL)</MenuItem>    
            </Select> : null

            }

{
                state === "Delhi" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Tata Power - DDL'>Tata Power - DDL</MenuItem>
              <MenuItem value="BSES Rajdhani - Delhi">BSES Rajdhani - Delhi</MenuItem>
              <MenuItem value="New Delhi Municipal Council (NDMC)">New Delhi Municipal Council (NDMC)</MenuItem>
              <MenuItem value="BSES Yamuna - Delhi">BSES Yamuna - Delhi</MenuItem>


              
    
            </Select> : null

            }
            
          </FormControl> : null
      }

    


      {
        board ?
        <Box sx={{ marginTop:'0.5rem', width:{lg:450, xs:250} }}  >
            <TextField onChange={(e) => setAccount(e.target.value)} fullWidth placeholder='Enter Account Number' type='number'  variant='standard'  />  
        </Box>
        
        : null
      }


      {

        state && board  && account  ? <Button color='secondary' onClick={() => billPay()} variant='contained' fullWidth sx={{ fontFamily:'montserrat' , fontWeight:500, marginTop:"1rem" }} >submit</Button> : null

      }
         
        </DialogContent>

      </Dialog>

    </Box>
  )
}

export default NewCat