import { OutlinedFlag } from '@mui/icons-material'
import { AppBar, Box, Button, OutlinedInput, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../images/Withe.png'

const Login = () => {

  const navigate = useNavigate();

  const [name , setName] = React.useState('');
  const [ password , setPassword] = React.useState('');


  return (
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', height:"100vh", flexDirection:'column'  }} >
      <AppBar position='fixed'  >
        <Toolbar>
          <Box component='img' src={logo} sx={{ width:"4.8rem" }} />
        </Toolbar>
      </AppBar>
            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:"2.5rem", border:'1px solid grey', padding:{lg:"1.5rem 2rem", xs:"1rem 1.5rem"} ,borderRadius:1  }} >

              <Typography sx={{fontFamily:'montserrat', fontSize:{lg:"1.5rem", xs:"1rem"}, fontWeight:500, color:"#858585" , textTransform:'capitalize' }} >  user login</Typography>

                <TextField  focused label='Enter Username' value={name} onChange={(e) => setName(e.target.value)}  sx={{ width:{lg:350 , xs:250, fontFamily:'montserrat'}}} />
                <TextField focused label='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)} sx={{ width:{lg:350 , xs:250}}} />

                <Button variant='contained' sx={{fontFamily:'montserrat', width:{lg:350 , xs:250} }} onClick={() => navigate('/cat', {state : {data : name}}) } >Submit</Button>

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:'grey', fontSize:13, }} >doesn't have an account yet?</Typography>
                  <Typography onClick={() => navigate("/register")} sx={{fontFamily:'montserrat', fontWeight:500, color:'blue', fontSize:13, cursor:"pointer", textDecoration:'underline'}} >&nbsp; register yourself</Typography>
                </Box>

            </Box>
            
            
    </Box>
  )
}

export default Login 