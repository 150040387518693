import { AppBar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Toolbar, Typography } from '@mui/material';
import React from 'react'
import { useLocation } from 'react-router-dom';
import PhonelinkRingIcon from '@mui/icons-material/PhonelinkRing';
import EastIcon from '@mui/icons-material/East';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import ShopIcon from '@mui/icons-material/Shop';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';





const Categories = () => {

    const [elect, setElect] = React.useState(false);
    const [state , setState] = React.useState('');
    const [board , setBoard] = React.useState('');
    const [account , setAccount] = React.useState('');
    
    const location = useLocation();

    const data = location.state.data;
    console.log("data : ",data);

    const handleElectClose = () => {
        setElect(false);
      };

      const handleChange = (event) => {
        setState(event.target.value);
        console.log("satte : ",event.target.value)
      };

      const handleBoard = (event) => {
        setBoard(event.target.value);
        console.log("board : ",event.target.value)
      }


      function billPay(){
          window.location.reload();
        window.alert("bill paid successfully");
      }

  return (
    <Box >
    <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , flexDirection:'column'}} >
        <AppBar position='sticky' color='secondary' >
            <Toolbar sx={{ display:"flex", alignItems:'center', justifyContent:"space-between" }} >
                <Typography sx={{ fontFamily:"montserrat", fontSize:{lg:"1.2rem", xs:"0.8rem"} }} >RanaPay&nbsp; Recharge Categories</Typography>
                <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{lg:20, xs:10} }} >{data}</Typography>
            </Toolbar>
        </AppBar>


            <Typography sx={{ fontFamily:'montserrat', marginTop:"2rem", fontWeight:600, fontSize:{lg:"2rem", xs:"1.2rem"}, color:"#dd33fa", marginTop:"2rem", textTransform:'capitalize' }}  >Recharge and  Pay your bill</Typography>

        <Box sx={{ marginTop:"3rem", display:'grid', gridTemplateColumns:{lg:'repeat(3,1fr)', md:'repeat(2,1fr)', xs:'repeat(1,1fr)'}, gap:{lg:"4rem", xl:"6rem" ,xs:"1rem"}, alignItems:'center', justifyContent:'center' }} >



        <Paper onClick={() => setElect(true)} elevation={12} sx={{  padding:"1rem 2rem" , width:280, cursor:'pointer' }} className='paperComp'  >
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , gap:"1rem" }} >
                    <Box  >

                <Typography color="secondary" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:20 , textTransform:'capitalize' }} >Electricity Recharge</Typography>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:'#8c8c87', fontSize:12, marginTop:"0.6rem" }} >Recharge your mobile or pay mobile bill with ease</Typography>
                <EastIcon sx={{marginTop:'1rem'}} />

                    </Box>
                <ElectricBoltIcon sx={{fontSize:{lg:60, xs:80}}} color='secondary' />
                    
                </Box>
                
            </Paper>



            <Paper onClick={() => window.alert("coming soon...")} elevation={12} sx={{  padding:"1rem 2rem" , width:280, cursor:'pointer' }} className='paperComp' >
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , gap:"1rem" }} >
                    <Box  >

                <Typography color="secondary" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:20 , textTransform:'capitalize' }} >water bill & recharge </Typography>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:'#8c8c87', fontSize:12, marginTop:"0.6rem" }} >Recharge your mobile or pay mobile bill with ease</Typography>
                <EastIcon sx={{marginTop:'1rem'}} />

                    </Box>
                <WaterDropIcon sx={{fontSize:{lg:60, xs:70}}} color='secondary' />
                    
                </Box>
                
            </Paper>




            <Paper onClick={() => window.alert("coming soon...")} elevation={12} sx={{  padding:"1rem 2rem" , width:280, cursor:'pointer' }} className='paperComp' >
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , gap:"1rem" }} >
                    <Box  >

                <Typography color="secondary" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:20 , textTransform:'capitalize' }} >Mobile Recharge and mobile bills</Typography>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:'#8c8c87', fontSize:12, marginTop:"0.6rem" }} >Recharge your mobile or pay mobile bill with ease</Typography>
                <EastIcon sx={{marginTop:'1rem'}} />

                    </Box>
                <PhonelinkRingIcon sx={{fontSize:{lg:60, xs:70}}} color='secondary' />
                    
                </Box>
                
            </Paper>

         

            

            <Paper onClick={() => window.alert("coming soon...")} elevation={12} sx={{  padding:"1rem 2rem" , width:280, cursor:'pointer' }} className='paperComp' >
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , gap:"1rem" }} >
                    <Box  >

                <Typography color="secondary" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:20 , textTransform:'capitalize' }} >DTH and TV Recharge</Typography>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:'#8c8c87', fontSize:12, marginTop:"0.6rem" }} >Recharge your mobile or pay mobile bill with ease</Typography>
                <EastIcon sx={{marginTop:'1rem'}} />

                    </Box>
                <ConnectedTvIcon sx={{fontSize:{lg:60, xs:70}}} color='secondary' />
                    
                </Box>
                
            </Paper>

            <Paper onClick={() => window.alert("coming soon...")} elevation={12} sx={{  padding:"1rem 2rem" , width:280, cursor:'pointer' }} className='paperComp' >
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , gap:"1rem" }} >
                    <Box  >

                <Typography color="secondary" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:20 , textTransform:'capitalize' }} >GooglePlay Recharge</Typography>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:'#8c8c87', fontSize:12, marginTop:"0.6rem" }} >Recharge your mobile or pay mobile bill with ease</Typography>
                <EastIcon sx={{marginTop:'1rem'}} />

                    </Box>
                <ShopIcon sx={{fontSize:{lg:60, xs:70}}} color='secondary' />
                    
                </Box>
                
            </Paper>

            <Paper onClick={() => window.alert("coming soon...")} elevation={12} sx={{  padding:"1rem 2rem" , width:280, cursor:'pointer' }} className='paperComp' >
                <Box sx={{ display:'flex', alignItems:"center", justifyContent:"center" , gap:"1rem" }} >
                    <Box  >

                <Typography color="secondary" sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:20 , textTransform:'capitalize' }} >book cylinder</Typography>
                <Typography sx={{ fontFamily:'montserrat', fontWeight:400, color:'#8c8c87', fontSize:12, marginTop:"2rem" }} >Recharge your mobile or pay mobile bill with ease</Typography>
                <EastIcon sx={{marginTop:'1rem'}} />

                    </Box>
                <PropaneTankIcon sx={{fontSize:{lg:60, xs:70}}} color='secondary' />

                </Box>
                
            </Paper>


        </Box>

        <AppBar position='sticky' color="secondary" sx={{marginTop: 'calc(10% + 60px)', bottom:0, display:"flex", alignItems:"center", justifyContent:"center"}} >
                <Typography sx={{textAlign:'center', fontSize:{lg:"1rem", xs:'0.7rem'}, padding:"0.5rem 0rem" }} >© Copyright 2023 - RanaPay | Privacy Policy | Disclaimer</Typography>
        </AppBar>
    </Box>

    <Dialog
        open={elect}
        onClose={handleElectClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth:{lg:"500px", xs:450},  // Set your width here
              },
            },
          }}      >
        <DialogTitle sx={{ fontFamily:"montserrat", textAlign:'center', width:{lg:450, xs:250}}} id="alert-dialog-title">
          {"Pay Electricity Bill"}
        </DialogTitle>
        <DialogContent sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:"column", gap:"1rem" }} >



        <FormControl variant='standard' sx={{ width:{lg:450, xs:250} }}>
        <InputLabel id="demo-simple-select-label"> State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="select state"
          value={state}
          onChange={handleChange}
        >
          <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
         
        </Select>
      </FormControl>


      {
            state ?   <FormControl variant='standard' sx={{ width:{lg:450, xs:250} }}>
            <InputLabel id="demo-simple-select-label"> Board</InputLabel>
    
        
            {
                state === "Uttar Pradesh" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Dakshinanchal Vidyut Vitran Nigam Limited (DVVNL)'>Dakshinanchal Vidyut Vitran Nigam Limited (DVVNL)</MenuItem>
              <MenuItem value="Madhyanchal Vidyut Vitran Nigam Limited (MVVNL)">Madhyanchal Vidyut Vitran Nigam Limited (MVVNL)</MenuItem>
              <MenuItem value="Paschimanchal Vidyut Vitran Nigam Limited (PVVNL)">Paschimanchal Vidyut Vitran Nigam Limited (PVVNL)</MenuItem>
              <MenuItem value="Purvanchal Vidyut Vitaran Nigam Limited (PUVVNL)">Purvanchal Vidyut Vitaran Nigam Limited (PUVVNL)</MenuItem>
              <MenuItem value="Uttar Pradesh Power Corporation Ltd. (UPPCL)">Uttar Pradesh Power Corporation Ltd. (UPPCL)</MenuItem>

    
            </Select> : null

            }

{
                state === "Punjab" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Punjab State Power Corporation Ltd. (PSPCL)'>Punjab State Power Corporation Ltd. (PSPCL)</MenuItem>
    
            </Select> : null

            }

{
                state === "Rajasthan" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Ajmer Vidyut Vitran Nigam Ltd. (AVVNL)'>Ajmer Vidyut Vitran Nigam Ltd. (AVVNL)</MenuItem>
              <MenuItem value="Bharatpur Electricity Services Ltd. (BESL)">Bharatpur Electricity Services Ltd. (BESL)</MenuItem>
              <MenuItem value="Bikaner Electricity Supply Limited">Bikaner Electricity Supply Limited</MenuItem>
              <MenuItem value="Jaipur Vidyut Vitran Nigam Ltd. (JVVNL)">Jaipur Vidyut Vitran Nigam Ltd. (JVVNL)</MenuItem>    
            </Select> : null

            }

{
                state === "Delhi" ?
                <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="select state"
              onChange={handleBoard}
            >
        
              <MenuItem value='Tata Power - DDL'>Tata Power - DDL</MenuItem>
              <MenuItem value="BSES Rajdhani - Delhi">BSES Rajdhani - Delhi</MenuItem>
              <MenuItem value="New Delhi Municipal Council (NDMC)">New Delhi Municipal Council (NDMC)</MenuItem>
              <MenuItem value="BSES Yamuna - Delhi">BSES Yamuna - Delhi</MenuItem>


              
    
            </Select> : null

            }
            
          </FormControl> : null
      }

    


      {
        board ?
        <Box sx={{ marginTop:'0.5rem', width:{lg:450, xs:250} }}  >
            <TextField onChange={(e) => setAccount(e.target.value)} fullWidth placeholder='Enter Account Number' type='number'  variant='standard'  />  
        </Box>
        
        : null
      }


      {

        state && board  && account  ? <Button color='secondary' onClick={() => billPay()} variant='contained' fullWidth sx={{ fontFamily:'montserrat' , fontWeight:500, marginTop:"1rem" }} >submit</Button> : null

      }
      




 
         
        </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </Dialog>

    </Box>
  )
}

export default Categories