import { AppBar, Box, Button, TextField, Toolbar, Typography } from '@mui/material'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../images/Withe.png"

const Register = () => {

    const [name , setName] = React.useState('');
    const [mobile , setMobile] = React.useState('');
    const [pass , setPass] = React.useState('');
    const [ confirm , setConfirm ] = React.useState('');

    const navigate = useNavigate();

    function getData(){

        window.alert(`registration successfully done ${name}`);
        
        setName("");
        setMobile("");
        setPass("");

        navigate("/");

        console.log("password : ",pass);
        console.log("confirm password : ",confirm)
    }



  return (
    <Box sx={{  }} >
        <AppBar position='fixed'  >
            <Toolbar sx={{display:'flex', alignItems:"center", justifyContent:'space-between',}} >

            <Box component='img' src={logo} sx={{ width:"4.8rem" }} />
            <Typography sx={{ fontFamily:"montserrat", fontWeight:400 }}  >User Registration</Typography>  

            </Toolbar>
        </AppBar>

        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column", minHeight:'100vh' }} >

        <Box sx={{display:'grid', gridTemplateColumns:"repeat(1,1fr)", alignItems:'center', justifyContent:"center", gap:'2rem' }} >

            <TextField  onChange={(e) => setName(e.target.value)} value={name} sx={{width:"25rem"}} focused label="Enter Name" />
            <TextField  value={mobile} onChange={(e) => setMobile(e.target.value)} sx={{width:"25rem"}} focused label="Enter Mobile Number" />
            <TextField   value={pass} onChange={(e) => setPass(e.target.value) } focused label="Enter Password" />

            <Button onClick={() => getData()}  variant='contained' size="large" >submit</Button>
        </Box>
        </Box>

    </Box>
  )
}

export default Register